.main-outer {
  display: flex;
  justify-content: center;
  // background-color: violet;
  width: 100%;
  height: 200vh;
}

.main-cont {
  // background-color: aqua;
  width: 60%;
}

.main-head {
  margin-top: 5rem;
  // background-color: chartreuse;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  img {
    width: 10rem;
    height: 10rem;
    margin-bottom: 1rem;
  }
  h1 {
    font-weight: 900;
  }
}
//

.main-summary {
  p {
    // background-color: blueviolet;
    font-weight: 600;
    font-size: 1.3rem;
    color: rgb(91, 89, 89);
    text-align: justify;
  }
  .text-link {
    text-decoration: none;
  }
  .text-link:hover {
    text-decoration: underline;
  }
}

//

.main-skills {
  h3 {
    font-weight: 700;
  }
  img {
    width: 100%;
  }
}

//

.proj1 {
  display: flex;
  margin-bottom: 2rem;
  .proj1-text {
    h5 {
      color: rgb(49, 49, 49);
    }
    padding-right: 2rem;
    color: rgb(91, 89, 89);
    text-align: justify;
  }
}

.proj1-img img {
  width: 17rem;
  height: 17rem;
}

.proj2 {
  display: flex;
  margin-bottom: 2rem;
  .proj2-text {
    h5 {
      color: rgb(49, 49, 49);
    }
    padding-left: 3rem;
    color: rgb(91, 89, 89);
    text-align: justify;
  }
}

.proj2-img img {
  width: 17rem;
  height: 17rem;
}

.proj3 {
  display: flex;
  margin-bottom: 2rem;
  .proj3-text {
    h5 {
      color: rgb(49, 49, 49);
    }
    padding-right: 2rem;
    color: rgb(91, 89, 89);
    text-align: justify;
  }
}

.proj3-img img {
  width: 17rem;
  height: 17rem;
}

.proj4 {
  display: flex;
  margin-bottom: 2rem;
  .proj4-text {
    h5 {
      color: rgb(49, 49, 49);
    }
    padding-left: 3rem;
    color: rgb(91, 89, 89);
    text-align: justify;
  }
}

.proj4-img img {
  width: 17rem;
  height: 17rem;
}

.proj-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.proj-icons {
  height: 1.8rem;
  width: 1.8rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.proj-icons:hover {
  height: 1.8rem;
  width: 1.8rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: lime;
}

//

.main-projects {
  h3 {
    font-weight: 700;
  }
}

.main-footer {
  display: flex;
  justify-content: center;
  font-size: 1rem;
}

@media (min-width: 1250px) {
  .main-cont {
    width: 47rem;
  }
}

@media (max-width: 950px) {
  .proj1-img {
    display: none;
  }
  .proj2-img {
    display: none;
  }
  .proj3-img {
    display: none;
  }
  .proj4-img {
    display: none;
  }
  .main-projects {
    font-size: 1.2rem;
    .proj1-text {
      padding: 0;
    }
    .proj2-text {
      padding: 0;
    }
    .proj3-text {
      padding: 0;
    }
    .proj4-text {
      padding: 0;
    }
  }

  .main-skills h5 {
    font-size: 0.7rem;
  }
}

@media (max-width: 750px) {
  .main-head {
    img {
      width: 7rem;
      height: 7rem;
    }
    h1 {
      font-size: 1.3rem;
    }
  }

  .main-cont {
    width: 75%;
    h5 {
      font-size: 1rem;
    }
  }
  .main-summary {
    p {
      font-size: 0.8rem;
    }
  }

  .main-projects {
    font-size: 0.7rem;
    .proj1-text {
      padding: 0;
    }
    .proj2-text {
      padding: 0;
    }
    .proj3-text {
      padding: 0;
    }
    .proj4-text {
      padding: 0;
    }
  }
  .main-footer {
    p {
      font-size: 0.5rem;
    }
  }
  .main-skills h5 {
    font-size: 0.6rem;
  }
}

.skill-items {
  color: rgb(108, 108, 108);
}
